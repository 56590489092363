export const blockchain = {
  name: "ブロックチェーンエンジニア",
  gyoumu_naiyo: "ブロックチェーン技術を活用した自社サービス開発、受託開発",
  oubo_hissu: "Webシステム開発経験(3年以上)",
  oubo_kangei:
    "ブロックチェーンを活用したサービス開発経験 :: Azureを活用したサービス開発経験 :: AWS/GCP/Firebaseを活用したサービス開発経験 :: React/Vue.jsを使用したフロントエンド開発経験",
  koyo_ketai: "正社員",
  kinmuti: "東京(フルリモートワーク可)",
  kinmujikan: "応相談 :: モデル例: 9:00〜18:00(休憩1時間)",
  kyuka:
    "完全週休2日制(土・日)、祝日 :: 夏期休暇、年末年始休暇 :: 年次有給休暇",
  hukuri_kose:
    "健康保険、厚生年金保険、雇用保険、労災保険 :: 書籍購入支援、オンライン講座(英会話・エンジニアリング)受講支援 :: 資格取得支援制度、資格取得報奨金制度",
}

export const plm = {
  name: "PLMエンジニア",
  gyoumu_naiyo: "製造業のライフサイクルシステム開発支援業務",
  oubo_hissu: "高卒以上",
  oubo_kangei: "未経験可（プログラミングスクールや職業訓練校卒業）",
  koyo_ketai: "正社員",
  kinmuti: "東京(フルリモートワーク可)",
  kinmujikan: "応相談 :: モデル例: 9:00〜18:00(休憩1時間)",
  kyuka:
    "完全週休2日制(土・日)、祝日 :: 夏期休暇、年末年始休暇 :: 年次有給休暇",
  hukuri_kose:
    "健康保険、厚生年金保険、雇用保険、労災保険 :: 書籍購入支援、オンライン講座(英会話・エンジニアリング)受講支援 :: 資格取得支援制度、資格取得報奨金制度",
}

export const client = {
  name: "フロントエンドエンジニア",
  gyoumu_naiyo:
    "フロントエンドフレームワークを利用したシステム開発業務（React Angular Vue）",
  oubo_hissu: "Webシステム開発経験(3年以上)",
  oubo_kangei:
    "フロントエンドフレームワークを利用した開発経験者（React Angular Vue）",
  koyo_ketai: "正社員",
  kinmuti: "東京(フルリモートワーク可)",
  kinmujikan: "応相談 :: モデル例: 9:00〜18:00(休憩1時間)",
  kyuka:
    "完全週休2日制(土・日)、祝日 :: 夏期休暇、年末年始休暇 :: 年次有給休暇",
  hukuri_kose:
    "健康保険、厚生年金保険、雇用保険、労災保険 :: 書籍購入支援、オンライン講座(英会話・エンジニアリング)受講支援 :: 資格取得支援制度、資格取得報奨金制度",
}

export const server = {
  name: "サーバーサイドエンジニア",
  gyoumu_naiyo: "Ruby on Railsを利用したシステム開発業務",
  oubo_hissu: "Webシステム開発経験(3年以上)、未経験も応募可能",
  oubo_kangei: "Ruby on Railsを利用したシステム開発経験",
  koyo_ketai: "正社員",
  kinmuti: "東京(フルリモートワーク可)",
  kinmujikan: "応相談 :: モデル例: 9:00〜18:00(休憩1時間)",
  kyuka:
    "完全週休2日制(土・日)、祝日 :: 夏期休暇、年末年始休暇 :: 年次有給休暇",
  hukuri_kose:
    "健康保険、厚生年金保険、雇用保険、労災保険 :: 書籍購入支援、オンライン講座(英会話・エンジニアリング)受講支援 :: 資格取得支援制度、資格取得報奨金制度",
}

export const intern = {
  name: "インターン",
  gyoumu_naiyo: "ブロックチェーンを用いた研究開発",
  oubo_hissu:
    "高校生以上、未経験も応募可能 :: 学業を必ず優先し留年をしない、必ず卒業すると約束できる方",
  oubo_kangei: "Ruby,Rust,C++,TypeScriptいずれかの開発経験",
  koyo_ketai: "アルバイト",
  kinmuti: "東京(フルリモートワーク可)",
  kinmujikan: "応相談 :: モデル例: 18:00〜21:00(週2回）",
  hukuri_kose:
    "健康保険、厚生年金保険、雇用保険、労災保険 :: 書籍購入支援、オンライン講座(英会話・エンジニアリング)受講支援 :: 資格取得支援制度、資格取得報奨金制度",
}
