import * as React from "react"
import styled from "@emotion/styled"
import Text from "./Text"
import { Color } from "../../../util/Color"
import { Divider, Typography, Button } from "@mui/material"

const Table = ({ view, data, submit }) => {
  return (
    <>
      {view === "MOBILE" || <Margin margin="72" />}
      <STypography
        variant={view === "PC" ? "h5" : view === "TABLET" ? "h6" : "subtitle1"}
      >
        {data.name}
      </STypography>
      <SDivider view={view} />
      <Text view={view} label="業務内容" text={data.gyoumu_naiyo} />
      <Text view={view} label="応募条件 (必須)" text={data.oubo_hissu} />
      <Text view={view} label="応募条件 (歓迎)" text={data.oubo_kangei} />
      <Text view={view} label="雇用形態" text={data.koyo_ketai} />
      <Text view={view} label="勤務地" text={data.kinmuti} />
      <Text view={view} label="勤務時間" text={data.kinmujikan} />
      {data.kyuka && <Text view={view} label="休日・休暇" text={data.kyuka} />}
      <Text view={view} label="福利厚生" text={data.hukuri_kose} />
      <ButtonWrapper>
        <SButton variant="outlined" onClick={submit}>
          応募
        </SButton>
      </ButtonWrapper>
    </>
  )
}

export default Table

const Margin = styled("div")(({ margin }) => ({
  height: margin + "px",
  width: "1px",
}))

const Img = styled("div")({
  width: "100%",
  "& > div": {
    width: "100%",
  },
})

const SDivider = styled(Divider)(({ view }) => ({
  height: "1px",
  width: "100%",
  background: Color.orange,
  border: "none",
  marginTop: view === "MOBILE" ? "16px" : "32px",
}))

const STypography = styled(Typography)({
  margin: "8px",
  fontWeight: 700,
  color: Color.black,
  fontFamily: "Noto Sans JP",
})

const SButton = styled(Button)({
  borderRadius: "200px",
  fontWeight: 700,
  color: Color.black,
  borderColor: Color.orange,
  ":hover": {
    borderColor: Color.orange,
    background: Color.orange,
    color: "white",
  },
  ":active": {
    borderColor: Color.orange,
  },
})

const ButtonWrapper = styled("div")({
  display: "flex",
  width: "100%",
  marginTop: "38px",
  justifyContent: "flex-end",
  "> button": {
    fontFamily: "Noto Sans JP",
    padding: "6px 40px",
  },
})
