import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Color, addAlpha } from "../../util/Color"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { Divider, Typography } from "@mui/material"
import Table from "../../components/feature/Recruitment/Table"
import {
  blockchain,
  plm,
  client,
  server,
  intern,
} from "../../datas/recruitment"
import { Button } from "@mui/material"
import { navigate } from "gatsby"
import CenterTitle from "../../components/feature/Home/CenterTitle"

const Recruitment = () => {
  const submit = () => {
    navigate("/contact")
  }

  const [view, setView] = React.useState("PC")

  React.useEffect(() => {
    const w = window.innerWidth
    setView(
      (() => {
        if (1440 < w) return "PC"
        else if (960 < w) return "TABLET"
        else return "MOBILE"
      })()
    )
  })

  return (
    <Layout>
      <Seo title="Recruitment" />
      {view === "MOBILE" ? (
        <CenterTitle en="Recruit" ja="採用" />
      ) : (
        <Img>
          <StaticImage
            src="../../components/feature/Recruitment/back.png"
            alt="top"
          />
        </Img>
      )}

      <Body view={view}>
        <Table view={view} data={plm} submit={submit} />
        <Table view={view} data={intern} submit={submit} />
        <Table view={view} data={client} submit={submit} />
        <Table view={view} data={server} submit={submit} />
        <Table view={view} data={blockchain} submit={submit} />
      </Body>
    </Layout>
  )
}

export default Recruitment

const Body = styled("div")(({ view }) => ({
  margin: view === "MOBILE" ? "0px 24px" : "0px 20vw",
  width: view === "MOBILE" ? "calc(100vw - 48px)" : "60vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
}))

const Margin = styled("div")(({ margin }) => ({
  height: margin + "px",
  width: "1px",
}))

const Img = styled("div")({
  width: "100%",
  "& > div": {
    width: "100%",
  },
})

const SDivider = styled(Divider)(({ view }) => ({
  height: "1px",
  width: "100%",
  background: Color.orange,
  border: "none",
  marginTop: view === "MOBILE" ? "16px" : "32px",
}))

const STypography = styled(Typography)({
  margin: "8px",
  fontWeight: 700,
  color: Color.black,
  fontFamily: "Noto Sans JP",
})

const SButton = styled(Button)({
  borderRadius: "200px",
  fontWeight: 700,
  color: Color.black,
  borderColor: Color.orange,
  ":hover": {
    borderColor: Color.orange,
    background: Color.orange,
    color: "white",
  },
  ":active": {
    borderColor: Color.orange,
  },
})

const ButtonWrapper = styled("div")({
  display: "flex",
  width: "100%",
  marginTop: "38px",
  justifyContent: "flex-end",
  "> button": {
    fontFamily: "Noto Sans JP",
    padding: "6px 40px",
  },
})
