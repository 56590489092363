import styled from "@emotion/styled"
import * as React from "react"
import { Color } from "../../../util/Color"

const CenterTitle = ({ en, ja }) => {

  const [view, setView] = React.useState('PC')

  React.useEffect(() => {
    const w = window.innerWidth
    setView((() => {
      if (1440 < w) return "PC"
      else if (960 < w) return "TABLET"
      else return "MOBILE"
    })())
  })

  return (
    <Wrap>
      <English view={view}>{en}</English>
      <Japanese view={view}>{ja}</Japanese>
    </Wrap>
  )
}

export default CenterTitle

const Wrap = styled('span')(({view}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '32px',
})
)
const English = styled('div')(({view}) => ({
  color: Color.orange,
  fontSize: '72px',
  fontFamily: 'Roboto',
  fontFeatureSettings: '"palt" 1',
}))
const Japanese = styled('div')(({view}) => ({
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
  color: '#161616',
  fontSize: '24px',
  marginTop: '-30px',
  marginLeft: view === 'MOBILE' ? '0px' : '32px'
}))